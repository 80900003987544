import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Links`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://shinshin86.com/"
        }}>{`作った音楽もろもろ`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/shinshin86"
        }}>{`GitHub`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://shinshin86.hateblo.jp"
        }}>{`はてなブログ`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://qiita.com/shinshin86"
        }}>{`Qiita`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      